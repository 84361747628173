/* -------------Select Inputs--------------------- */
.redesignActive {
    /* Styling for all input elements */
input {
    // border-radius: 40px !important;
    // height: 38px;
}

.ant-input-number {
    border-radius: 40px !important;
    // height: 38px;
}

.ant-input-number-input {
    height: 38px;
    padding-bottom: 1px;
  }

/* Styling for Ant Design's input elements */
.ant-input {
    -webkit-font-feature-settings: normal!important;
    font-feature-settings: normal!important;
    font-variant: normal!important;
    font-size: 11px;
    font-family: 'AkkuratLLWeb-Regular';
}

/* Hover effect for Ant Design's input elements */
.ant-input:hover {
    border-color: @primary;
}
.ant-input-number:hover {
    border-color: @primary;
}

/* Focus effect for Ant Design's input elements */
.ant-input:focus, :focus-within {
    border-color: @primary;
}
.ant-input-number-focused {
    border-color: @primary;
    box-shadow: none;
}

/* Styling for the placeholder text of Ant Design's input elements */
.ant-input::placeholder {
    -webkit-font-feature-settings: normal !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
    font-size: 12px;
}

.disabled-placeholder-clear {
    .ant-input-disabled {
      color: @soft-black;
    }
  }

  .helper-first {
    margin-bottom: 28px;
    .ant-form-item-control {
        flex-direction: column-reverse;

        > *:nth-child(2) {
            margin-bottom: 8px;
        }
    }
  }

  .only-helper {
    .ant-form-item-control-input {
        display: none;
    }
  }
  }
  /* -------------Inputs--------------------- */