//-------------Buttons-----------------------------
.redesignActive {
  .ant-btn {
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 5px;
    box-shadow: none;
    height: 38px;
  }

  .ant-btn-general {
    border-radius: 20px;
    text-transform: uppercase;
    text-shadow: none;
  }

  .ant-btn-colors {
    background: @button-primary;
    border: 1px solid @button-primary;
  }

  .ant-btn-ghost {
    border: 1px solid @light-gray;
    text-transform: uppercase;
    background: @white;
  }

  .ant-btn-ghost:hover {
    border: 1px solid @light-gray;
  }

  .ant-btn-ghost:hover {
    color: @soft-black;
  }

  .ant-btn-ghost:active,
  .ant-btn-ghost:focus {
    border: 1px solid @light-gray;
    background: @white;
    color: @black;
  }

  .ant-btn-primary {
    color: @black;
    .ant-btn-colors;
    .ant-btn-general;
  }

  .ant-btn-primary:hover {
    background: @button-hovering;
    border: 1px solid @button-hovering;
    .ant-btn-general;
  }

  .ant-btn-primary:active,
  .ant-btn-primary:focus {
    .ant-btn-colors;
    color: @black;
    border-radius: 20px;
  }

  .ant-btn-default {
    background-color: @soft-black;
    border-color: @soft-black;
    color: @white;
    padding: 10px 20px;
    border-radius: 35px;
    .ant-btn-general;
  }

  .ant-btn-default.ant-btn-dangerous,.ant-btn-default.ant-btn-dangerous:hover  {
    color: @soft-black;
    background-color: @redBase;
    border-color: @redBase;
  }

  .ant-btn-default:hover {
    background-color: @soft-black-hover;
    border-color: @soft-black-hover;
  }

  .ant-btn-default[disabled],
  .ant-btn-primary[disabled] {
    background-color: @cultered;
    border-color: @cultered;
    color: rgba(0, 0, 0, 0.25);
    svg {
      path {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .ant-btn-default[disabled]:hover,
  .ant-btn-primary[disabled]:hover {
    background-color: @cultered;
    border-color: @cultered;
  }

  .ant-btn-link {
    font-size: 13px;
    color: @button-primary !important;
  }

  // filter button animation as shown in the transactions page
  .filter-button {
    transition: background-color 0.3s, color 0.3s;
  }

  .filter-button.active {
    background-color: @white; /* Adjust color as per your theme */
  }

  .filter-button svg {
    transition: transform 0.3s ease-in-out;
  }

  .filter-button.active svg {
    transform: rotate(180deg); /* Adjust the rotation angle as per your preference */
  }

  .link {
    background: none;
    border: none;
    color: @primary;
    cursor: pointer;
    padding: 0;
  }

  .no-style-button {
    cursor: pointer;
    background: none;
    border: none;
    color: inherit;
    padding: 0
  }
}

//-------------Buttons-----------------------------
